// 个人中心 // 播放历史
<template>
  <div id="app3">
    <!-- 顶部 -->
    <Top :white="$store.state.model.black" ref="top"></Top>

    <div class="history_body">
      <!-- 左侧 -->
      <Leftside></Leftside>
      <transition name="RightToleft">
        <!-- 右侧主题 -->
        <div class="his_r_box" v-show="enter">
          <div class="history_body_right">
            <div class="his_r_title"><span>账户信息</span></div>
            <!-- 主体 -->
            <div class="his_r_body">
              <None v-if="havedata"></None>
              <div class="acc">
                <div class="acc_list">
                  <div><span>用户名</span></div>
                  <div>
                    <span class="acc_name">{{ $store.state.user.username }}</span>
                    <!-- <a class="acc_none">修改</a> -->
                  </div>
                </div>
                <div class="acc_list">
                  <div><span>昵称</span></div>
                  <div>
                    <span>{{ $store.state.user.nickname }}</span>
                    <a class="acc_modify" @click="YS.gotoVip($event, 'open', 'userReg')">修改</a>
                  </div>
                </div>

                <!-- <div class="acc_list">
                <div><span>会员级别</span></div>
                <div>
                  <span>普通会员</span>
                  <a class="acc_yellow" @click="YS.gotoVip($event, 'open', 'vipReg')">充值</a>
                </div>
              </div> -->
                <div class="acc_list">
                  <div><span>VIP到期时间</span></div>
                  <div>
                    <span>
                      {{
                        Date.parse(new Date()).toString().substr(0, 10) >
                        $store.state.user.vip_expired_time
                          ? '您还不是会员'
                          : YS.toTime2($store.state.user.vip_expired_time)
                      }}
                    </span>
                    <a class="acc_yellow" @click="leftTovip">充值</a>
                  </div>
                </div>
                <!-- 上传头像 -->
                <div class="acc_list">
                  <div><span>头像</span></div>
                  <div>
                    <!-- <span class="acc_mail">{{ $store.state.user.email }}</span> -->
                    <a class="acc_zi" @click="YS.gotoVip($event, 'open', 'upavatar')">上传头像</a>
                    <!-- <input
                      type="file"
                      tabIndex="-1"
                      accept="image/jpeg,image/x-png,image/gif"
                      name="file"
                      style="padding-left: 10px"
                      id="fileToUpload"
                      @change="fileSelected()"
                    /> -->
                  </div>
                </div>
                <!-- 电子邮箱 -->
                <div class="acc_list">
                  <div><span>电子邮箱</span></div>
                  <div>
                    <span class="acc_mail">{{ $store.state.user.email }}</span>
                    <a
                      v-if="!$store.state.user.email"
                      class="acc_zi"
                      @click="YS.gotoVip($event, 'open', 'mailReg')"
                    >
                      {{ $store.state.user.email ? '已绑' : '绑定' }}
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
    <!-- 修改用户名弹窗 -->
    <transition name="show">
      <div class="acc_res_win" v-show="$store.state.showlogin.userReg">
        <div class="login-logo"><img src="../assets/img/logo.png" alt="logo图标" /></div>
        <!-- <div class="login-a">
        <span>账户信息</span>
      </div> -->
        <div id="loginIpu" class="login-body">
          <!-- 单标签头 -->
          <div class="for-tag">
            <div id="ltc" class="lt-cc">
              <span id="ltcspan">修改昵称</span>
            </div>
          </div>
          <!-- 账号密码输入框 -->
          <div class="lt-d">
            <input class="lt-input" v-model="nickname" placeholder="请输入新的昵称" />
            <div class="lt-acc">
              <div class="acc_re_div" @click="resetUsername"><a class="acc_re">立即修改</a></div>
            </div>
          </div>
        </div>
        <img
          class="login-close"
          @click="YS.gotoVip($event, 'close', 'userReg')"
          src="../assets/img/close.png"
          alt="关闭修改用户名弹窗"
        />
      </div>
    </transition>
    <!-- 绑定邮箱弹窗 -->
    <transition name="show">
      <div class="respowwin_mail" v-show="$store.state.showlogin.mailReg">
        <div class="login-logo"><img src="../assets/img/logo.png" alt="logo图标" /></div>
        <div id="loginIpu" class="sec_code_body">
          <!-- 单标签头 -->
          <div class="for-tag">
            <div id="ltc" class="lt-cc">
              <span id="ltcspan">绑定邮箱</span>
            </div>
          </div>
          <!-- 账号密码输入框 -->
          <div class="lt-d">
            <input class="lt-input" v-model="resmail.email" placeholder="请输入邮箱" />
            <div id="codeIpu" class="sec_code_inpu">
              <input
                class="lt-input"
                maxlength="6"
                v-model="resmail.captcha"
                placeholder="请输入邮箱验证码"
              />
              <a id="codea" class="varcode" @click="getmailver">{{ $store.state.code.count }}</a>
            </div>
          </div>
        </div>
        <!-- 登录/注册按钮 -->
        <div class="lt-f">
          <div class="lt-acc">
            <div class="sec_re_div" @click="climailRes"><a class="acc_re">立即绑定</a></div>
          </div>
        </div>
        <img
          class="login-close"
          @click="YS.gotoVip($event, 'close', 'mailReg')"
          src="../assets/img/close.png"
          alt="关闭绑定邮箱弹窗"
        />
      </div>
    </transition>

    <!-- 上传头像弹窗 -->
    <transition name="show">
      <div class="acc_res_win" v-show="$store.state.showlogin.upavatar">
        <div class="login-logo"><img src="../assets/img/logo.png" alt="关闭上传头像弹窗" /></div>
        <!-- <div class="login-a">
        <span>账户信息</span>
      </div> -->
        <div id="loginIpu" class="login-body">
          <!-- <form id="fileform" enctype="multipart/form-data">
            <input type="file" name="file" />
            <br />
            <input type="submit" value="submit" />
            <a @click="suba">上传二</a>
          </form> -->
          <form id="form1" enctype="multipart/form-data" method="post" action="">
            <div style="height: 0px; overflow: hidden; position: absolute">
              <input
                type="file"
                tabIndex="-1"
                accept="image/jpeg,image/x-png,image/gif"
                name="file"
                style="padding-left: 10px"
                id="fileToUpload"
                @change="fileSelected()"
              />
            </div>
            <button
              type="button"
              class="btn btn-default btn-xs"
              onclick="document.getElementById('fileToUpload').click()"
            >
              上传
            </button>
          </form>
        </div>
        <img
          class="login-close"
          @click="YS.gotoVip($event, 'close', 'upavatar')"
          src="../assets/img/close.png"
          alt="关闭上传头像弹窗"
        />
      </div>
    </transition>

    <!-- 遮罩层 -->
    <!-- <div id="cover"></div> -->
  </div>
</template>
<script>
let that;
import Top from '../components/Top'; //导航top
import Leftside from '../components/Leftside'; //左侧主题
import None from '../components/None'; //无内容

export default {
  components: {
    Top,
    Leftside,
    None,
  },
  name: 'History',
  data() {
    return {
      imglisttype: ['png', 'jpg', 'jpeg', 'bmp', 'gif', 'jfif', 'pjpeg'],
      resmail: {
        email: '',
        captcha: '',
        phrase_id: '',
      },
      nickname: '', //重置用户名
      enter: false,
      havedata: false, //控制 显示没有任何内容 组件展示};
    };
  },
  created() {
    that = this;
    //var ismob = /Android|webOS|iPhone|iPod|BlackBerry/i.test(navigator.userAgent);
    that.YS.toMobile('/user');
  },
  mounted() {
    that.enter = true;
    that.YS.closeallWin(); //关闭所有弹窗
    that.YS.actlist(that.$store.state.leftListcss, 3, 'history_lc_list_act'); //添加默认选中样式
    that.YS.iconAct(that.$store.state.leftComponent, 3); //添加默认选中样式
    that;
  },
  methods: {
    resetUsername() {
      that.api.user
        .api_changeNickname({ nickname: that.nickname })
        .then((data) => {
          var tem_local = JSON.parse(localStorage.getItem('user'));
          if (tem_local) {
            tem_local.nickname = data.user.nickname;
            localStorage.setItem('user', JSON.stringify(tem_local));
            // alert('修改成功');
            that.$refs.top.usepubilDialog({ title: '修改昵称成功', type: 'vip-card', code: true });
            that.$store.commit('changelogin');
            that.YS.gotoVip({}, 'close', 'userReg');
          }
        })
        .catch((error) => {
          that.$refs.top.usepubilDialog({ title: error.message, type: 'vip-card', code: false });
        });
    },
    //获取邮箱验证码
    getmailver() {
      that.$store.state.code.count === '验证码' &&
        that.api.user.api_emailCode({ email: that.resmail.email }).then((data) => {
          // console.log(that.resmail.email);
          // console.log(data.phrase_id);
          that.YS.getCode();
          that.resmail.phrase_id = data.phrase_id;
        });
    },
    //修改绑定邮箱
    climailRes() {
      // console.log(that.resmail);
      that.api.user
        .api_secritybindEmail(that.resmail)
        .then((data) => {
          var aaa = JSON.parse(localStorage.getItem('user'));
          aaa.email = data.user.email;
          localStorage.setItem('user', JSON.stringify(aaa));
          that.$store.commit('changelogin');
          // alert('绑定邮箱成功');
          that.$refs.top.usepubilDialog({ title: '绑定邮箱成功', type: 'vip-card', code: true });
          that.YS.gotoVip(1, 'close', 'mailReg');
        })
        .catch((error) => {
          // alert(error.message);
          that.$refs.top.usepubilDialog({ title: error.message, type: 'vip-card', code: false });
        });
    },
    suba() {},
    fileSelected() {
      var that = this;
      let files = document.getElementById('fileToUpload').files;
      if (files && files.length) {
        var fd = new FormData();

        // 图片大小 1M
        var fileSize = 1024 * 1024;
        // reader.readAsDataURL(files[0]);
        fd.append('avatar', files[0]);
        // var asd = fd.get('avatar');
        // asd;
        var ftype = files[0].type;
        // that.imglisttype.forEach((e) => {
        //   if (ftype.indexOf(e) < 0) {
        //     alert('请上传正常格式的图片！');
        //   }
        // });
        var type = false;
        for (var i of that.imglisttype) {
          if (ftype.indexOf(i) > 0) {
            type = true;
            break;
          } else {
            type = false;
          }
        }
        if (!type) {
          // alert('请上传正常格式的图片！');
          that.$refs.top.usepubilDialog({
            title: '请上传正常格式的图片！',
            type: 'vip-card',
            code: false,
          });
          return false;
        }
        if (files[0].size > fileSize && type) {
          // that.$dispatch('show-alert', 'msg_1016');
          // document.getElementById('fileToUpload').value = '';
          // alert('文件过大,请上传小于1Mb的图片！');
          that.$refs.top.usepubilDialog({
            title: '文件过大,请上传小于1Mb的图片！！',
            type: 'vip-card',
            code: false,
          });
          return false;
        } else if (type) {
          that.api.user.api_uploadavatar(fd).then((data) => {
            var tem_local = JSON.parse(localStorage.getItem('user'));
            tem_local.avatar = data.user.avatar;
            localStorage.setItem('user', JSON.stringify(tem_local));
            that.$store.commit('changelogin');
            // alert('上传成功');
            that.$refs.top.usepubilDialog({
              title: '头像上传成功！',
              type: 'vip-card',
              code: true,
            });
            that.YS.gotoVip(1, 'close', 'upavatar');
          });
          // var xhr = new XMLHttpRequest();
          // xhr.addEventListener('load', that.uploadComplete, false);
          // xhr.open('POST', window.g.BASE_URL + '/upload/avatar');
          // xhr.send(fd);
          // console.log(fd);
        }
      }
    },
    //点击开通vip
    leftTovip() {
      that.$refs.top.wantVIP();
    },
  },
};
</script>
<style lang="scss" scope>
// .top-nav {
//   background: white;
// }
// .tag-input {
//   background: #eeeeee;
//   // border: 1px solid rgba(255, 255, 255, 0.43137254901960786);
// }

// .tag-span,
// .right-content span {
//   color: rgba(51, 51, 51, 1);
// }
// .bubble {
//   background: #91d6ff;
// }
</style>
